import 'bootstrap/dist/css/bootstrap.css';
import getintoshape from "../../assets/getintoshape.png";
// import sleep from "../../assets/sleep.png"
import { useNavigate } from "react-router-dom";

import "../../styles/index.css";


function GetIntoShapePage() {
  let navigate = useNavigate();

  return (
    <>
    <section style={{marginTop:"2%"}} class="container py-3 py-md-5 getIntoShape">
      <div class="container overflow-hidden">
        <div class="row gy-5">
          <div class="col-12">
            <div class="row align-items-center gy-3 gy-md-0 gx-xl-5">
              <div class="col-xs-12 col-md-6">
                <div class="img-wrapper position-relative bsb-hover-push">
                  <a href="#!">
                    <img  class="img-fluid rounded w-100 h-100 object-fit-cover" loading="lazy" src={getintoshape} alt="Sports" />
                  </a>
                </div>
              </div>
              <div class="col-xs-12 col-md-6">
                <div>
                  <h2 style={{fontWeight:500, fontSize:48}} class=" mb-3"><a class="link-dark text-decoration-none" href="#!">
                    Get into the best 
                    <br/>shape you’re proud of.
                   
                  </a></h2>

                  <p style={{fontWeight:500}} class="fs-6 mb-4">
                   The food you eat impacts your calorie intake and metabolism, 
                   influencing weight gain and body energy levels. Consult nutrition 
                   experts for advice to achieve and maintain a body weight you’re 
                   proud of, and stay strong all day long.
                  
                     
                  </p>
                  <button onClick={() =>{ navigate("/waitlist")}} style={{borderColor:'#30B15C', borderWidth: 3, color: '#30B15C', fontWeight:'bold'}} class="btn fs-5 btn-lg btn-outline-secondary rounded-pill px-20" href="#!" target="_self">Join Waitlist</button>

                 
                </div>

                
              </div>

              
            </div>
          </div>
        </div>
      </div>
    </section>



    {/* <-- Show on Mobile, Hide on Laptop ---> */}
    <section class="container py-3 py-md-5 getIntoShape-mb">
      <div class="container overflow-hidden">
        <div class="row gy-5">
          <div class="col-12">
            <div class="row align-items-center gy-3 gy-md-0 gx-xl-5">
              <div class="col-xs-12 col-md-6">
                <div class="img-wrapper position-relative bsb-hover-push">
                  <a href="#!">
                    <img  class="img-fluid rounded w-100 h-100 object-fit-cover" loading="lazy" src={getintoshape} alt="Sports" />
                  </a>
                </div>
              </div>
              <div class="col-xs-12 col-md-6">
                <div>
                  <h2 style={{fontWeight:500, fontSize:28}} class=" mb-3"><a class="link-dark text-decoration-none" href="#!">
                    Get into the best 
                    <br/>shape you’re proud of.
                   
                  </a></h2>

                  <p style={{fontWeight:500}} class="fs-6 mb-4">
                   The food you eat impacts your calorie intake and metabolism, 
                   influencing weight gain and body energy levels. Consult nutrition 
                   experts for advice to achieve and maintain a body weight you’re 
                   proud of, and stay strong all day long.
                  
                     
                  </p>
                  <button onClick={() =>{ navigate("/waitlist")}} style={{borderColor:'#30B15C', borderWidth: 3, color: '#30B15C', fontWeight:'bold'}} class="btn fs-5 btn-lg btn-outline-secondary rounded-pill px-20" href="#!" target="_self">Join Waitlist</button>

                 
                </div>

                
              </div>

              
            </div>
          </div>
        </div>
      </div>
    </section>
  </>
   
  );
}

export default GetIntoShapePage;


