import 'bootstrap/dist/css/bootstrap.css';
//https://bootstrapbrain.com/component/bootstrap-table-card-snippet/#preview
import topRecipe from "../../assets/topRecipe.png";

import { shoppingListTop } from './data';


function ShoppingListTableDown() {
  return (
    <>
        <section  style={{marginTop:"-5%"}} class="py-3 py-md-5">
        <div class="container">
            <div class="row justify-content-center">
            <div class="col-12 col-lg-9 col-xl-12">
                <div class="card widget-card border-light shadow-sm">
                <div class="card-body p-4">
                    <div style={{lineHeight:0.5, marginTop:"2%"}}>
                        <p style={{color:"#101840", fontSize:20, fontWeight:600}} class="card-title widget-card-title mb-4">Unhealthy ingredients</p>
                        <div style={{display:'flex', flexDirection:"row",}}>
                            {/* <img style={{marginTop:-1.2, marginRight:7}} class="h-50" src={topRecipe} alt="✓" /> */}
                            <p style={{color:"#696F8C", fontSize:16, fontWeight:400}} class="mt-0 mb-4">These are the list of ingredients that are unhealthy on the user’s profile  </p>
                        </div>
                    </div>
                    
                    <div class="table-responsive">
                    <table class="table table-border table-hover bsb-table-xl text-nowrap align-middle m-0">
                        <thead>
                        <tr>
                        </tr>
                        </thead>
                        <tbody>
                            {shoppingListTop.map(u => (
                                <tr key={u.key}>
                                    <td style={{padding:20, fontSize:14, marginRight:"-5%"}}>
                                            <div style={{ fontSize:16, fontWeight:600, color:"#101840", display:"flex",}} className="small text-gray">
                                                <img  style={{height:"5%", width:"5%", marginRight:"2%"}} src="https://demos.creative-tim.com/test/corporate-ui-dashboard/assets/img/team-2.jpg" class="avatar  avatar-sm rounded-circle " alt="user1" />
                                                   <span> {  u.food} </span>
                                            </div>
                                    </td>
                                    <td style={{padding:20, fontSize:16, fontWeight:600, color:"#696F8C",  backgroundColor:"white", width:10}}>
                                        <div >Add flag </div>
                                    </td>
                                    <td class="fw-bold" style={{padding:20, fontSize:16, fontWeight:500, color:"#30B15C", backgroundColor:"white", width:10}}>
                                        Edit
                                    </td>
                                    {/* <td class="fw-normal" style={{padding:20, fontSize:14, fontWeight:700, color:"#3366FF"}}>
                                        <span className="fw-bold">{u.saveRate}</span>

                                        <div style={{height:3.5, }} className="progress">
                                            <div
                                                className="progress-bar"
                                                role="progressbar"
                                                style={{ width: '60%', height:3.5, backgroundColor:"#3366FF" }}
                                                aria-valuenow="60"
                                                aria-valuemin="0"
                                                aria-valuemax="100"
                                            ></div>
                                        </div>

                       
                                    </td> */}
                                </tr>
                            ))}

                        </tbody>
                    </table>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
        </section>

    </>
  );
}

export default ShoppingListTableDown;





