import 'bootstrap/dist/css/bootstrap.css';

//https://www.npmjs.com/package/react-dropdown-select
import Select from "react-dropdown-select";


import React, { useState } from 'react';
import { Container, Form, Modal, ButtonGroup, InputGroup, FormControl, Button,
          Dropdown, DropdownToggle, DropdownMenu, DropdownItem
} from 'react-bootstrap';
// import { Modal } from 'bootstrap';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';

import { mealPlanner } from './data';

import "../../styles/index.css";

const DropdownExample = () => {
  const [dropdownOpen, setDropdownOpen] = React.useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}  style={{marginTop:"5%", marginBottom:"5%" }} >
      <DropdownToggle caret style={{
        backgroundColor: '#fff', color:"#30B15C", borderColor:"#30B15C", borderWidth:3, fontWeight:600, fontSize:22, borderRadius:50
      }}>
        Healthy-living
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem>MEAL PLANNER</DropdownItem>
        <DropdownItem>Healthy-living</DropdownItem>
        <DropdownItem>Vegan</DropdownItem>
        <DropdownItem>Keto</DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};


const SortBy = () => {
  const [dropdownOpen, setDropdownOpen] = React.useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
      <DropdownToggle caret>
        Sort by: Categories
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem>Option 1</DropdownItem>
        <DropdownItem>Option 2</DropdownItem>
        <DropdownItem>Option 3</DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};


const ToggleSwitch = () => {
  const [selected, setSelected] = useState(false);

  const handleClick = () => {
    setSelected(!selected);
  };

  return (
    <ButtonGroup>
      <Button
        variant={selected ? 'light' : 'success'}
        onClick={handleClick}
        style={{
          backgroundColor: selected ? '#ffffff' : '#00ff00',
          padding: '10px 20px',
          borderRadius: '20px',
          cursor: 'pointer',
          width: '60px',
          textAlign: 'center'
        }}
      >
        {selected ? 'Unselected' : 'Selected'}
      </Button>
    </ButtonGroup>
  );
};


const SearchBar = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  const myArray = [
    {
      id: 1,
      name: 'Newest'
    },
    {
      id: 2,
      name: 'Oldest'
    }
  ];

  const handleSearch = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    const searchResults = myArray.filter((item) => item.toLowerCase().includes(searchTerm));
    setSearchResults(searchResults);
  };

  return (
    <InputGroup style={{height:10, width:"100%"}}>
      <Button variant="outline-secondary">
        <i className="fas fa-search"></i>
      </Button>
      <FormControl
        type="search"
        value={searchTerm}
        onChange={handleSearch}
        placeholder="Search by items"
      />
      
      <ul>
        {searchResults.map((item) => (
          <li key={item}>{item}</li>
        ))}
      </ul>
    </InputGroup>
  );
};

// function ToggleSwitch() {
//   const [isSelected, setIsSelected] = useState(false);

//   return (
//     <div className="custom-control custom-switch">
//       <input
//         type="checkbox"
//         className="custom-control-input"
//         id="customSwitch"
//         checked={isSelected}
//         onChange={() => setIsSelected(!isSelected)}
//       />
//       <label className="custom-control-label" htmlFor="customSwitch">
//         Selected
//       </label>
//     </div>
//   );
// }

// const ToggleSwitch = () => {
//   const [state, setState] = useState(false);

//   const handleClick = () => {
//     setState(!state);
//   };

//   return (
//     <ButtonGroup>
//       <Button
//         variant={state ? 'success' : 'danger'}
//         onClick={handleClick}
//         style={{
//           backgroundColor: state ? '#007bff' : '#ff0000',
//           padding: '10px 20px',
//           borderRadius: '20px',
//           cursor: 'pointer',
//         }}
//       >
//         {state ? 'On' : 'Off'}
//       </Button>
//     </ButtonGroup>
//   );
// };



// const SortBy = () => {
//   const [search, setSearch] = React.useState("");

//   const options = [
//       {
//         id: 1,
//         name: 'Newest'
//       },
//       {
//         id: 2,
//         name: 'Oldest'
//       }
//     ];
//     return (
//       <>
//         <div className="input-group mb-3">
//           {/* <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> */}
//           <Select style={{width:200, height: 21 }}
//             options={options}
//             labelField="name"
//             valueField="id"
//             onChange={(values) => {setSearch(values.id)} }
//             placeholder="Sort by: Categories"
//           />
//         </div>          
//       </>
//     );
//   };

  


const MealPlannerIndex = () => {
    // For Modal
    const [showModal, setShowModal] = React.useState(false);
    const [showModal2, setShowModal2] = React.useState(false);

    const handleCellClick = () => {
      setShowModal(true);
  };

    const handleCellClick2 = () => {
        setShowModal2(true);
    };

    const handleModalClose = () => {
        setShowModal(false);
    };

    const handleModalClose2 = () => {
      setShowModal2(false);
  };
    //End Of For Modal
    

  const [mealData, setMealData] = useState({});
  const [selectedDate, setSelectedDate] = useState(new Date());

  const handleMealChange = (date, meal) => {
    setMealData((prevData) => ({ ...prevData, [date]: meal }));
  };

  const handleSubmit = () => {
    fetch('/api/meal-planner', {
      method: 'POST',
      body: JSON.stringify(mealData),
    });
  };

  return (
    <>
    <section  style={{marginTop:"-5%"}} class="py-3 py-md-5">
        {/* <div class="container">
            <div class="row justify-content-center">
            <div class="col-12 col-lg-9 col-xl-12"> */}
              <Container class="table-responsive">
                <DropdownExample />
                
                  <FullCalendar
                  style={{  height:600, width:600 }}
                  plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                  headerToolbar={{
                    right: 'prev,next',
                    left: 'title',
                    initialView: "dayGridMonth",
                  //   right: 'dayGridMonth,timeGridWeek,timeGridDay',
                  }}
                  selectable={true}
                  select={handleDateSelect}
                  editable={true}
                  eventClick={handleEventClick}
                  events={mealData}
                  dayCellContent={({ date }) => {
                      const meals = mealData[date.toISOString()];
                      return (
                        <div>
                          <br />
                          {meals ? (
                            <ul>
                              {Object.keys(meals).map((meal) => (
                                <li key={meal}>{meal}</li>
                              ))}
                            </ul>
                          ) : (
                            <span>Set meal plan</span>
                          )}
                        </div>
                      );
                    }
                  }


                />



                  <Modal show={showModal} onHide={handleModalClose} aria-labelledby="contained-modal-title-vcenter" centered >
                      <Modal.Header>
                          <Modal.Title style={{ color:"#101840", fontSize:20, fontWeight:600 }}>
                              Picks for today
                              <span style={{ color:"#696F8C", fontSize:16, fontWeight:400 }}><br /> Select the meal of the day to plan</span>
                          </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                          <ul style={{listStyle:"none", marginLeft:"-8%" }} >
                              <li>
                                  <Button style={{border:"none", background:"none", color:"#000", fontSize:18, fontWeight:500 }} onClick={()=> {handleCellClick2()} } >Breakfast</Button>
                              </li>
                              <li>
                                  <Button style={{border:"none", background:"none", color:"#000", fontSize:18, fontWeight:500 }} >Lunch</Button>
                              </li>
                              <li>
                                  <Button style={{border:"none", background:"none", color:"#000", fontSize:18, fontWeight:500 }}>Supper</Button>
                              </li>
                              <li>
                                  <Button style={{border:"none", background:"none", color:"#000", fontSize:18, fontWeight:500 }}>Snacks</Button>
                              </li>
                          </ul>
                      </Modal.Body>
                  </Modal>


                  <Modal show={showModal2} onHide={handleModalClose2} size='lg' aria-labelledby="contained-modal-title-vcenter" centered >
                      <Modal.Header>
                          <Modal.Title style={{ color:"#101840", fontSize:20, fontWeight:600, }}>
                              <div style={{marginTop:"5%"}}>
                                Lunch
                              </div>
                            <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between" }} >
                              <span style={{ color:"#696F8C", fontSize:16, fontWeight:400, width:"100%", display:"flex", marginTop:5 }}>
                                  <br /> 
                                  Select the meals for today
                              </span>
                              <SearchBar  />
                              <SortBy  />
                            </div>
                              
                              
                          </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                      <table class="table table-sm table-hover table-borderedless">
                      <tbody>
                          {mealPlanner.map(u => (
                              <tr key={u.key}>
                                  <td style={{padding:10, fontSize:14, display:"flex", flexDirection:"row"}}> 
                                    <div style={{display:"flex", flexDirection:"row"}} >
                                      <img  style={{height:"70%", width:"9%", marginRight:"2%"}} src="https://demos.creative-tim.com/test/corporate-ui-dashboard/assets/img/team-2.jpg" class="avatar  avatar-sm rounded-circle " alt="user1" />
                                      <div>
                                        <span style={{fontSize:16, fontWeight:600, color:"#474D66"}} >{u.food}</span>
                                        <br /><span style={{fontSize:14, fontWeight:400, color:"#474D66"}}>{u.description}</span>
                                      </div>
                                    </div>

                                    <div style={{fontSize:14, fontWeight:600, color:"#474D66"}}>
                                      {u.selectedTxt}
                                      {/* <ToggleSwitch /> */}
                                    </div>
                                  </td>
                              </tr>
                          ))}
                          
                      </tbody>
                  </table>
                      </Modal.Body>

                      <Modal.Footer>
                        <div class="col-12">
                              <div  style={{justifyContent:"center", display:"flex", flexDirection:"row", marginTop:"4%"}} >
                                <button style={{backgroundColor:'#51D27D', height:50, width:"40%", borderRadius:20, color:'white' }} class="btn  fw-bold" type="submit">Save</button>
                              </div>
                          </div>
                      </Modal.Footer>
                  </Modal>



                
                {/* <Form>
                  <Form.Group controlId="breakfast">
                    <Form.Label>Breakfast</Form.Label>
                    <Form.Control
                      type="text"
                      value={mealData.breakfast}
                      onChange={(e) => handleMealChange(selectedDate, { breakfast: e.target.value })}
                    />
                  </Form.Group>
                  <Form.Group controlId="lunch">
                    <Form.Label>Lunch</Form.Label>
                    <Form.Control
                      type="text"
                      value={mealData.lunch}
                      onChange={(e) => handleMealChange(selectedDate, { lunch: e.target.value })}
                    />
                  </Form.Group>
                  <Form.Group controlId="supper">
                    <Form.Label>Supper</Form.Label>
                    <Form.Control
                      type="text"
                      value={mealData.supper}
                      onChange={(e) => handleMealChange(selectedDate, { supper: e.target.value })}
                    />
                  </Form.Group>
                  <Button onClick={handleSubmit}>Submit Meal Plan</Button>
                </Form> */}
              </Container>
            
            {/* </div>
          </div>
        </div> */}
      </section>
    </>
  );




  function handleDateSelect(arg) {
    setSelectedDate(arg.date);
    // alert('hello boy');
    handleCellClick();
  }

  function handleEventClick(arg) {
    setSelectedDate(arg.event.start);
  }

};
 

export default MealPlannerIndex;


