import 'bootstrap/dist/css/bootstrap.css';
import adddark from "../../assets/adddark.png";
import credpalmission from "../../assets/credpalmission.png";

import "../../styles/index.css";


function OurMissionPage() {
  return (
    <>
        <div class="ourmission" style={{marginTop:"5%"}} >
        <div style={{ display:"flex", justifyContent:"center", }} class="overflow-hidden text-center py-3 py-md-5">
         <section class="bsb-hero-1 px-3 bsb-overlay bsb-hover-pull img-fluid w-100 h-100 object-fit-cover " style={{backgroundImage: `url(${credpalmission})`,  backgroundRepeat:'no-repeat', backgroundSize:'cover' }}>
            {/* <section class="bsb-hero-1 px-3 bsb-overlay bsb-hover-pull" style={{height:800, backgroundImage: `url(${credpalmission})`, backgroundRepeat:'no-repeat', backgroundAttachment:"fixed", backgroundSize:'100% 100%' }}> */}
                <div class="container" >
                    <h1 style={{ fontWeight:"500"}} class="fs-4">OUR MISSION</h1>
                    <div class="col-lg-8 text-center mx-auto">
                        <p style={{ fontWeight: 400, fontSize:50}} class=" mx-0 h2 mb-4" >
                            <img class="position-absolute" src={adddark} style={{marginLeft: -35,  marginTop: -10}} alt="+" />Helping Africans eat healthier
                            foods and become better 
                            versions of themselves.
                           <img class="position-absolute" src={adddark} style={{marginLeft: -5,  marginTop: -7}} alt="+"  />
                        </p>
                        <p style={{fontWeight:500}} class="mx-5 text-center mb-4">
                            Livio is the leading global healthy eating platform, founded on the
                            belief that food fuels life. Livio is democratising healthy eating by
                            empowering people with tools to understand their personal nutritional
                            needs. This enables smarter food choices, whether you want to reach a
                            healthy weight, build better eating habits, run faster, improve your sleep
                            or extend your longevity. The Livio app tracks food intake and
                            exercise habits, and offers personalised feedback, recipes and meal
                            plans to suit your lifestyle.
                        </p>
                    </div>

                    <h1 style={{color:"#06E775", fontWeight:"400", fontSize:48}} >Become a better you!</h1>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    
            
                </div>
            </section>
        </div>
        </div>



        {/* <-- Show on Mobile, Hide on Laptop ---> */}
        <div class="ourmission-mb" style={{marginTop:"8%"}} >
        <div style={{ display:"flex", justifyContent:"center" }} class=" overflow-hidden text-center py-3 py-md-5">
         <section class="bsb-hero-1 px-3 bsb-overlay bsb-hover-pull img-fluid " >
                <div class="container" >
                    <p style={{ fontWeight:"normal", fontSize:16}} >OUR MISSION</p>
                    <div class="col-lg-8 text-center mx-auto">
                        <h1 style={{ fontWeight: 600, fontSize: 18}} >
                        At Livio, We are dedicated to making nutrition accessible to Africans, unique by design, and powered by technology.
                        </h1>
                        <p style={{fontWeight:500, marginTop:"5%"}} class="text-center mb-4">
                            Livio is the leading global healthy eating platform, founded on the belief that food fuels life. Livio is democratizing healthy eating by empowering people with tools to understand their personal nutritional needs. This enables smarter food choices, whether you want to reach a healthy weight, build better eating habits, run faster, improve your sleep or extend your longevity. The Livio app tracks food intake and exercise habits, and offers personalized feedback, recipes and meal
                            plans to suit your lifestyle.
                        </p>
                    </div>

                    <h1 style={{color:"#06E775", fontSize:22, fontWeight:600}} >Eat well, live well.</h1>
            
                </div>
            </section>
        </div>
        </div>
        {/* <-- End Of Show on Mobile, Hide on Laptop ---> */}


    </>
  );
}

export default OurMissionPage;


