import React, { useState,  useRef } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'react-bootstrap';

import { Carousel, CarouselItem, CarouselControl, CarouselIndicator } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
//https://www.educba.com/bootstrap-vertical-menu/
//https://stackoverflow.com/questions/48248975/bootstrap-navbar-collapse-dont-work-react-create-react-app
// https://getbootstrap.com/docs/4.0/components/navbar/

// import dashboardLogo from "../../assets/dashboardLogo.png";
import dashboard from "../../assets/dashboard.png";
import arrowRight from "../../assets/arrowRight.png";
import arrowLeft2 from "../../assets/arrowLeft2.png";

import smNavBarLogo1 from "../../assets/smNavBarLogo1.png";
import healthNavBar from "../../assets/healthNavBar.png";
import peopleNavBar from "../../assets/peopleNavBar.png";
import monitorNavBar from "../../assets/monitorNavBar.png";
import expandNavBar from "../../assets/expandNavBar.png";
import userNavBar from "../../assets/userNavBar.png";
import dashboardAvatar from "../../assets/dashboardAvatar.png";
import info from "../../assets/info.png";
import premium from "../../assets/premium.png";
import Avatar from "../../assets/Avatar.png";

import "../../styles/hover.css"

// import DashboardPageIndex from '.';
import DashboardRecipeTable from './dashboardRecipeTable';
import DashboardRecipeGraphIndex from './dashboardRecipeGraphIndex';
import MealPlannerIndex from './mealPlanner';
import ShoppingListIndex from './shopingListIndex';

const Slider = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [components, setComponents] = useState([
        { text: 'User Preferences', component: <DashboardRecipeTable /> },
        { text: 'Meal Planner', component: <MealPlannerIndex /> },
        { text: 'Shopping List', component: <ShoppingListIndex /> },
        { text: 'Referrals', component: <DashboardRecipeGraphIndex /> },
        { text: 'Orders', component: <DashboardRecipeGraphIndex /> },
    ]);

    // const carousel = React.createRef();
    // const carousel= useRef(null);
  
    const handleTextClick = (index) => {
      setActiveIndex(index);
    //   carousel.select(index);
    //   carousel.current.select(index);
    };

    
  
    return (
      <div>
        <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between",  padding:10}} className="text-nav">
          {components.map((component, index) => (
            <span
              key={component.text}
              data-text={component.text}
              onClick={() => handleTextClick(index)}
              style={{
                fontSize:16, fontWeight:600,
                color: activeIndex === index ? '#30B15C' : '#101840',
              }}
            >
              {component.text}
              <div
                className="indicator"
                data-text={component.text}
                style={{
                  display: activeIndex === index ? 'block' : 'none',
                  width: '100%',
                  height: '2px',
                  backgroundColor: '#30B15C',
                  marginTop:"7%"
                }}
              />
            </span>
          ))}
        </div>
        <hr style={{border:"1px solid #8F95B2", marginTop:"-1%"}} />

        <Carousel  activeIndex={activeIndex} onSelect={handleTextClick} interval={null}>
          {components.map((component, index) => (
            <CarouselItem key={index}>
              {component.component}
            </CarouselItem>
          ))}
        </Carousel>
      </div>
    );
  };

// const Slider = () => {
//     const [activeIndex, setActiveIndex] = useState(0);
//     const [components, setComponents] = useState([
//       { text: 'User Preferences', component: <DashboardRecipeTable /> },
//       { text: 'Meal Planner', component: <DashboardRecipeGraphIndex /> },
//       { text: 'Shopping List', component: <DashboardRecipeTable /> },
//       { text: 'Referrals', component: <DashboardRecipeGraphIndex /> },
//       { text: 'Orders', component: <DashboardRecipeGraphIndex /> },
//     ]);
  
//     const handleSelect = (index) => {
//       setActiveIndex(index);
//     };
  
//     const handleTextClick = (text) => {
//       const index = components.findIndex((component) => component.text === text);
//       handleSelect(index);
//     };
  
//     return (
//       <div>
//         <div class="container" style={{display:"flex", flexDirection:"row", justifyContent:"space-between",  padding:10}} className="text-nav">
//           {components.map((component) => (
//             <span style={{fontSize:16, fontWeight:600, color:"#101840", }} key={component.text} onClick={() => handleTextClick(component.text)}>
//               {component.text}
//             </span>
//           ))}
//         </div>
//         <hr style={{border:"1px solid black"}} />
//         <Carousel bg="dark" activeIndex={activeIndex} onSelect={handleSelect}>
//           {components.map((component, index) => (
//             <CarouselItem key={index}>
//               {component.component}
//             </CarouselItem>
//           ))}
//         </Carousel>
//       </div>
//     );
//   };

const SuspendedDropDown = () => {
    const [dropdownOpen, setDropdownOpen] = React.useState(false);
  
    const toggleDropdown = () => {
      setDropdownOpen(!dropdownOpen);
    };
  
    return (
      <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}  style={{marginLeft:"-7%", marginTop:"-10%" }} >
        <DropdownToggle caret style={{
           color:"#996A13",  fontWeight:500, fontSize:16, border:"none", background:"none"
        }}>
            <img src={info} class="d-inline-block" alt="!" style={{marginRight: 8, marginTop:-3}}  /> 
            Suspended
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem>MEAL PLANNER</DropdownItem>
          <DropdownItem>Healthy-living</DropdownItem>
          <DropdownItem>Vegan</DropdownItem>
          <DropdownItem>Keto</DropdownItem>
        </DropdownMenu>
      </Dropdown>
    );
  };

  const PremiumDropDown = () => {
    const [dropdownOpen, setDropdownOpen] = React.useState(false);
  
    const toggleDropdown = () => {
      setDropdownOpen(!dropdownOpen);
    };
  
    return (
      <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}  style={{marginLeft:"-7%", marginTop:"-10%" }} >
        <DropdownToggle caret style={{
           color:"#3366FF",  fontWeight:500, fontSize:16, border:"none", background:"none"
        }}>
            <img src={premium} class="d-inline-block" alt=" ✓" style={{marginRight: 8, marginTop:-3}}  /> 
            Premium
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem>MEAL PLANNER</DropdownItem>
          <DropdownItem>Healthy-living</DropdownItem>
          <DropdownItem>Vegan</DropdownItem>
          <DropdownItem>Keto</DropdownItem>
        </DropdownMenu>
      </Dropdown>
    );
  };

  const UserName = () => {
    const [dropdownOpen, setDropdownOpen] = React.useState(false);
  
    const toggleDropdown = () => {
      setDropdownOpen(!dropdownOpen);
    };
  
    return (
      <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}  >
        <DropdownToggle caret style={{
           color:"#000000",  fontWeight:500, fontSize:14, border:"none", background:"none"
        }}>
            <img src={Avatar} class="d-inline-block" alt="😀" style={{marginRight: 8, marginTop:-3}}  /> 
            Victor Adeniyi
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem>MEAL PLANNER</DropdownItem>
          <DropdownItem>Healthy-living</DropdownItem>
          <DropdownItem>Vegan</DropdownItem>
          <DropdownItem>Keto</DropdownItem>
        </DropdownMenu>
      </Dropdown>
    );
  };

function MealPlannerNavBar() {
  return (
    <>
        <div style={{backgroundColor:"#FAFBFF"}} class="row">
            <div class="col-1" style={{ backgroundColor:"#fff",  }}>
                <nav class ="navbar" style={{backgroundColor:"#fff", width:"100%", marginLeft:5, }}>
                    {/* <button style={{marginTop:"20%", border:"none", marginLeft:"5%"}} class="navbar-brand navbar-toggler" data-toggle="collapse" data-target="#colp" href="#">
                        <img src={smNavBarLogo1}  class="d-inline-block align-top" alt="smNavBarLogo1" />
                    </button> */}
                    <div style={{backgroundColor:"#fff", margin:"auto"}} >
                        <ul class ="nav navbar-nav">
                            <li  class ="nav-item">
                                <a class="nav-link mybtn" href="#" style={{marginLeft: 2, padding:"20%", marginTop:"80%", marginBottom:'90%', borderRadius:5,  fontSize:14, color:"#696F8C", display:"flex" , justifyContent:"center",   }}>
                                    <div class="mybtn">
                                        <img src={smNavBarLogo1} class="d-inline-block" alt="smNavBarLogo1" style={{marginRight: 8, marginTop:-3}}  /> 
                                    </div>
                                </a>
                            </li>
                            <li  class ="nav-item">
                                <a class="nav-link mybtn" href="#" style={{marginLeft: 2, padding:"20%", marginBottom:'90%', borderRadius:5,  fontSize:14, color:"#696F8C", display:"flex" , justifyContent:"center",   }}>
                                        <div class="mybtn">
                                            <img src={dashboard} class="d-inline-block" alt="dashboard" style={{marginRight: 8, marginTop:-3}}  /> 
                                        </div>
                                </a>
                            </li>
                            <li  class ="nav-item">
                                <a class="nav-link mybtn" href="#" style={{marginLeft: 2, padding:"20%", marginBottom:'90%', borderRadius:5,  fontSize:14, color:"#696F8C", display:"flex" , justifyContent:"center",   }}>
                                        <div class="mybtn">
                                            <img src={userNavBar} class="d-inline-block" alt="userNavBar" style={{marginRight: 8, marginTop:-3}}  /> 
                                        </div>
                                </a>
                            </li>
                            <li  class ="nav-item">
                                <a class ="nav-link mybtn" href="#" style={{marginLeft: 2, padding:"20%", marginBottom:'90%', borderRadius:5, fontSize:14, color:"#696F8C",  display:"flex" , justifyContent:"center",   }}>
                                            <div class="mybtn">
                                                <img src={healthNavBar} class="d-inline-block" alt="healthNavBar" style={{marginRight: 8, marginTop:-3}}  /> 
                                            </div>
                                </a>
                            </li>
                            <li  class ="nav-item">
                                <a class ="nav-link mybtn" href="#" style={{marginLeft: 2, padding:"20%", marginBottom:'90%', borderRadius:5, fontSize:14, color:"#696F8C", display:"flex" , justifyContent:"center",   }}>
                                                <div class="mybtn">
                                                    <img src={peopleNavBar} class="d-inline-block" alt="peopleNavBar" style={{marginRight: 8, marginTop:-3}}  /> 
                                                </div>
                                </a>
                            </li>
                            <li  class ="nav-item">
                                <a class ="nav-link mybtn" href="#" style={{marginLeft: 2, padding:"20%", marginBottom:'100%', borderRadius:5, fontSize:14, color:"#696F8C", display:"flex" , justifyContent:"center",  }}>
                                                    <div class="mybtn">
                                                        <img src={monitorNavBar} class="d-inline-block" alt="monitorNavBar" style={{marginRight: 8, marginTop:-3}}  /> 
                                                    </div>
                                </a>
                            </li>


                            <li style={{marginTop:"100%", marginBottom:"100%"}} class="nav-item dropdown">
                                <hr style={{border:"1px solid black"}} />
                                <a class="nav-link mybtn" href="#" style={{marginLeft: 2, padding:"20%",  fontSize:14, color:"#696F8C",     }}>
                                    <div class="mybtn" style={{display:"flex" , justifyContent:"center",}}>
                                        <img src={expandNavBar} class="d-inline-block" alt="expandNavBar" style={{marginRight: 8, marginTop:-3}}  /> 
                                    </div>
                                    <div class="mybtn" style={{display:"flex" , justifyContent:"center",}}>
                                        <img src={expandNavBar} class="d-inline-block" alt="expandNavBar" style={{marginRight: 8, marginTop:-3}}  /> 
                                    </div>
                                </a>
                                {/* <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> */}
                                {/* Dropdown link */}
                                {/* </a> */}
                                {/* <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                <a class="dropdown-item" href="#">Action</a>
                                <a class="dropdown-item" href="#">Another action</a>
                                <a class="dropdown-item" href="#">Something else here</a>
                                </div> */}
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>

            <div style={{backgroundColor:"white"}} class="col-2">
                <div style={{marginTop:"13%"}}>
                    <p style={{fontSize:22, fontWeight:600, color:"#101840", display:"flex", justifyContent:"space-between" }} >
                        All Users
                        <img src={arrowLeft2} style={{marginTop:"6%",}} class="d-inline-block h-50"  alt=">" />  
                    </p>
                </div>
                <div style={{marginTop:"15%"}}>
                    <p style={{fontSize:14, fontWeight:400, color:"#696F8C"}}>EDIT PROFILE</p>
                </div>
                <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between", marginTop:"13%" }}>
                    <div style={{lineHeight:0.4}} >
                        <p style={{fontSize:18, fontWeight:600, color:"#101840"}}>Victor Adeniyi</p>
                        <p style={{fontSize:11, fontWeight:600, color:"#474D66"}}>JollofMate User</p>
                    </div>
                    <img style={{marginTop:"-4%"}} src={dashboardAvatar} class="avatar h-50  avatar-sm rounded-circle " alt="😀" />
                </div>

                <div style={{marginTop:"9%"}}>
                    <p style={{fontSize:16, fontWeight:600, color:"#474D66"}}>Account Status</p>
                    <SuspendedDropDown />
                </div>

                <div style={{marginTop:"9%"}}>
                    <p style={{fontSize:16, fontWeight:600, color:"#474D66"}}>Account Type</p>
                    <PremiumDropDown />
                </div>

                <hr style={{border:"1px solid black"}} />
                <div style={{marginTop:"16%"}}>
                    <div style={{lineHeight:0.7}} >
                        <p style={{fontSize:16, fontWeight:600, color:"#474D66"}}>Email address</p>
                        <p style={{fontSize:16, fontWeight:400, color:"#696F8C"}}>Victor.a@credpal.com</p>
                    </div>
                    <div style={{lineHeight:0.7, marginTop:"15%"}} >
                        <p style={{fontSize:16, fontWeight:600, color:"#474D66"}}>Goals</p>
                        <p style={{fontSize:16, fontWeight:400, color:"#696F8C"}}>Balanced Diet</p>
                    </div>
                </div>

                <hr style={{border:"1px solid black"}} />
                <div style={{marginTop:"16%"}}>
                    <div style={{lineHeight:0.7}} >
                        <p style={{fontSize:16, fontWeight:600, color:"#474D66"}}>Phone number</p>
                        <p style={{fontSize:16, fontWeight:400, color:"#696F8C"}}>+2348114568889</p>
                    </div>
                    <div style={{lineHeight:0.7, marginTop:"15%"}} >
                        <p style={{fontSize:16, fontWeight:600, color:"#474D66"}}>Date of Birth</p>
                        <p style={{fontSize:16, fontWeight:400, color:"#696F8C"}}>April 17th</p>
                    </div>
                </div>

                <hr style={{border:"1px solid black"}} />
                <div style={{marginTop:"16%"}}>
                    <div style={{lineHeight:0.7}} >
                        <p style={{fontSize:16, fontWeight:600, color:"#474D66"}}>Currrent weight</p>
                        <p style={{fontSize:16, fontWeight:400, color:"#696F8C"}}>75kg</p>
                    </div>
                    <div style={{lineHeight:0.7, marginTop:"15%"}} >
                        <p style={{fontSize:16, fontWeight:600, color:"#474D66"}}>Age</p>
                        <p style={{fontSize:16, fontWeight:400, color:"#696F8C"}}>23yrs</p>
                    </div>
                </div>
                
                <hr style={{border:"1px solid black"}} />
                <div style={{marginTop:"16%"}}>
                    <div style={{lineHeight:0.7}} >
                        <p style={{fontSize:16, fontWeight:600, color:"#474D66"}}>Gender</p>
                        <p style={{fontSize:16, fontWeight:400, color:"#696F8C"}}>Rather not say</p>
                    </div>
                    <div style={{lineHeight:0.7, marginTop:"15%"}} >
                        <p style={{fontSize:16, fontWeight:600, color:"#474D66"}}>Health Condition</p>
                        <p style={{fontSize:16, fontWeight:400, color:"#696F8C"}}>Diabetes, Surgery, Glycoma</p>
                    </div>
                </div>
                
                <hr style={{border:"1px solid black"}} />
                <div style={{marginTop:"16%"}}>
                    <div style={{lineHeight:0.7}} >
                        <p style={{fontSize:16, fontWeight:600, color:"#474D66"}}>Allergic ingredients</p>
                        <p style={{fontSize:16, fontWeight:400, color:"#696F8C"}}>Milk, Egg, Fish, Peanuts </p>
                    </div>
                </div>
                

            </div>



            <div style={{backgroundColor:"#FAFBFF"}} class="col-9">
                <div class="container" style={{display:"flex", justifyContent:"flex-end", marginTop:"3%" }}>
                    <UserName />
                </div>
                
                    {/* <DashboardPageIndex /> */}
                    {/* <DashboardRecipeGraphIndex />
                    <DashboardRecipeTable /> */}
                    <Slider />
            </div>
        </div>
        
      

    </>
  );
}

export default MealPlannerNavBar;





