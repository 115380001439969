import React, { useState} from "react";
import 'bootstrap/dist/css/bootstrap.css';
import waitlistform from "../../assets/waitlistform.png";
import waitlistbg from "../../assets/waitlistbg.png";
import NavBar from "../home/NavBar";
import { useNavigate } from "react-router-dom";
import success from "../../assets/success.png";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

import "../../styles/index.css"

function WaitlistPageIndex() {
  const [open, setOpen] = React.useState(false);
	const handleClose = () => setOpen(false);
	let navigate = useNavigate();


  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [number, setNumber] = useState("");


  const handleSubmit = async (e) => {
    e.preventDefault();

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("firstName", firstName);
    urlencoded.append("lastName", lastName);
    urlencoded.append("email", email);
    urlencoded.append("phoneNumber", `+234${number}`);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    };

    fetch("https://staging.api.circlenow.app/livio/api/v1/waitlist", requestOptions)
      .then(response => response.json())
      .then(result => {
        setOpen(true);
      }
      )
      .catch(error => console.log('error', error));

  }



  return (
    <>
      <NavBar />

      <section style={{marginTop:20}} class="p-3 p-md-4 p-xl-5">
        <div  class="container">
          {/* <div class="card border-light-subtle shadow-sm"> */}
            <div class="row ">

              <div style={{paddingRight:15}} class="col-12 col-md-6">
                {/* <div class="card-body p-3 p-md-4 p-xl-5"> */}
                  <div class="row">
                    <div  class="col-12">
                      {/* <img style={{marginTop:30}} src={credpalLogo} alt="BootstrapBrain Logo" width="100" height="40" />
               */}

                      <div class="mt-5">
                        <h2 class="h3 fw-bold">Join the Livio Waitlist</h2>
                        <h3 style={{marginTop:10}} class="fs-6 fw-normal text-secondary m-0">
                          Get early access to everything happening in the livio community!
                        </h3>
                      </div>

                    </div>
                  </div>
                  <form style={{marginTop:50,}} onSubmit={handleSubmit}>
                    <div class="row gy-3 gy-md-4 overflow-hidden">
                    <div  class="col-12 mt-3">
                        <label style={{color:"#474D66", fontWeight:'normal' }} for="email" class="form-label fw-bold">Email address <span class="text-danger">*</span></label>
                        <input value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          style={{height:50, borderRadius:10, }} type="email" class="form-control" name="email" id="email" placeholder="Enter your email address" required />
                    </div>

                      <div class="col-12">
                        <label style={{color:"#474D66", fontWeight:'normal' }} for="firstName" class="form-label fw-bold">First Name <span class="text-danger">*</span></label>
                        <input value={firstName}
                          onChange={(e) => setFirstName(e.target.value)}
                          style={{height:50, borderRadius:10, }} type="text" class="form-control" name="firstName" id="firstName" placeholder="Enter your first name" required />
                      </div>

                      <div class="col-12">
                        <label style={{color:"#474D66", fontWeight:'normal' }} for="firstName" class="form-label fw-bold">Last Name <span class="text-danger">*</span></label>
                        <input value={lastName}
                          onChange={(e) => setLastName(e.target.value)}
                          style={{height:50, borderRadius:10, }} type="text" class="form-control" name="firstName" id="firstName" placeholder="Enter your last name" required />
                      </div>

                      <div class="col-12">
                        <label style={{color:"#474D66", fontWeight:'normal' }} for="lastName" class="form-label fw-bold">Phone number <span class="text-danger">*</span></label>
                        <input value={number}
                          onChange={(e) => setNumber(e.target.value)}
                        style={{height:50, borderRadius:10, }} type="tel" class="form-control" name="lastName" id="lastName" placeholder="+234 " required />
                      </div>




                      <div class="col-12">
                        <div class="d-grid">
                          <button style={{backgroundColor:'#51D27D', height:50, borderRadius:10, color:'white' }} class="btn bsb-btn-xl fw-bold" type="submit">Join Waitlist</button>
                        </div>
                      </div>

                    </div>
                  </form>



                {/* </div> */}
              </div>


              {/* Hide Image on mobile */}
              <div style={{backgroundImage: `url(${waitlistbg})`,}} class="col-12 col-md-6 text-center waitlistIndex">
                <img style={{borderRadius:50 }} class="img-fluid rounded w-100 h-100 object-fit-cover" loading="lazy" src={waitlistform} alt="Waitlist"  />
              </div>
            </div>
          </div>
        {/* </div> */}





        <Modal
					open={open}
					onClose={handleClose}
					aria-labelledby="modal-modal-title"
					aria-describedby="modal-modal-description"
				>
					<Box style={{backgroundColor:'white',  top:'50%', width:400, position:'absolute', transform: 'translate(-50%, -50%)', left: '50%', borderRadius:'5%' }} >


						<div  class="modal-dialog modal-dialog-centered modal-md rounded " role="document">
							<div style={{borderRadius:20}} class="modal-content  ">
								<div class="modal-body text-center p-lg-2 ">
									<img style={{marginTop:50}} loading="lazy" src={success} alt="Success" />

									<h4 style={{fontWeight:'initial' }} class="text-dark mt-4 fw-bold fs-4">You are now part of the</h4>
									<p style={{color:'#30B15C', marginTop:-5 }} class=" fs-4 fw-bold">Livio family!</p>

									<p style={{color:'#696F8C'}} class="mt-3 fs-6 text-secondary">
										Get early access to everything
										<br/>happening in the livio community!
									</p>

									<hr style={{width:"100%", height:2, marginTop:60, marginLeft:0, marginRight:0 }} />
									<button  onClick={() =>{ navigate("/")}} type="button" style={{border:'none', alignSelf:"center", backgroundColor:"white", borderColor:'white', textDecoration:"underline", fontWeight:"bold", marginTop:40, marginBottom:40 }} class=" fs-5 text-dark link-secondary"  >Go Back Home</button>

								</div>
							</div>
						</div>

					</Box>

				</Modal>


      </section>

    </>
  );
}

export default WaitlistPageIndex;


