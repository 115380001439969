import "bootstrap/dist/css/bootstrap.css";
import NavBar from "./NavBar";
import HeroPage from "./HeroPage";
// import HeroPage2 from './components/HeroPage2';
import EatRightPage from "./EatRightPage";
import FeaturesPage from "./FeaturesPage";
import ExclusiveRecipePage from "./ExclusiveRecipePage";
import GetIntoShapePage from "./GetIntoShapePage";
// import SleepPage from './SleepPage';
// import MonitorMealPlanPage from './MonitorMealPlanPage';
import LiveLongPage from "./LiveLongPage";
import GetAccessPage from "./GetAccessPage";
import OurMissionPage from "./OurMissionPage";
import JoinWaitListPage from "./JoinWaitListPage";
import FooterPage from "./Footer";
import Footer from "../../components/footer/Footer";
import ChefTestimonialCard from "../../components/chefTestimonials/ChefTestimonials";
// import Footer2 from './Footer2';

function HomePageIndex() {
  return (
    <>
      <NavBar />
      {/* <HeroPage2 /> */}
      <HeroPage />
      <EatRightPage />
      <FeaturesPage />
      <ExclusiveRecipePage />
      <GetIntoShapePage />
      {/* <SleepPage /> */}
      <LiveLongPage />
      {/* <MonitorMealPlanPage /> */}
      <GetAccessPage />
      <ChefTestimonialCard />
      <OurMissionPage />
      <JoinWaitListPage />
      <Footer />
      {/* <FooterPage /> */}
      {/* <Footer2 /> */}
    </>
  );
}

export default HomePageIndex;
