import 'bootstrap/dist/css/bootstrap.css';
import getaccess from "../../assets/getaccess.png";
import applestore from "../../assets/applestore.svg";
import playstore from "../../assets/playstore.svg";
import { useNavigate } from "react-router-dom";
import getaccessMobile from "../../assets/getaccessMobile.png"

import "../../styles/index.css";

function GetAccessPage() {
  let navigate = useNavigate();

  return (
    <>
      <div style={{backgroundColor:"#F9FAFC"}}>
      <section  class="getaccess container py-3 py-md-5 ">
        <div style={{marginBottom:"-4%"}} class="container overflow-hidden">
          <div class="row gy-5 mt-1">
            <div class="col-12">
              <div class="row align-items-center gy-3 gy-md-0 gx-xl-5">
                <div class="col-xs-12 col-md-6">
                  <div class="img-wrapper position-relative bsb-hover-push">
                    <a href="#!">
                      <img  loading="lazy" src={getaccess} alt="getaccess" />
                    </a>
                  </div>
                </div>
                <div class="col-xs-12 col-md-6">
                  <div>
                    <p style={{fontWeight:500}} class="h1 dispaly-5 mb-3"><a class="link-dark text-decoration-none" href="#!">
                      Be the first to get access to
                      our app 
                      </a>
                    </p>
                    <p style={{fontWeight:500, marginTop:"4%" }} class="mb-4">
                     We are giving away exciting gifts for the first 1000 people to get the Livio App. Join the waitlist now to get notified immediately the app launches. We are excited to welcome you into our community of people who want to make smarter food choices.
                    </p>
                    <button onClick={() =>{ navigate("/waitlist")}} style={{borderColor:'#30B15C', borderWidth: 3, color: '#30B15C', fontWeight:'bold'}} class="btn fs-5 btn-lg btn-outline-secondary rounded-pill px-20" >Get Early Access</button>


                    <div style={{marginTop: 50}}>
                      <p class="text-secondary text-dark">Available soon on</p>
                      <div>
                        <img style={{width:"30%", marginRight:'5%'}} src={applestore}  alt="apple playstore" />
                        <img style={{width:"34%"}} src={playstore}  alt="google playstore" />
                      </div>
                    </div>

                  </div>
                </div>

                
              </div>
            </div>
          </div>
        </div>
      </section>
      </div>



      {/* <-- Show on Mobile, Hide on Laptop ---> */}
      <section style={{backgroundColor:"#F9FAFC",}} class="getaccess-mb container py-3 py-md-5">
        <div class="container overflow-hidden">
          <div class="row gy-5">
            <div class="col-12">
              <div class="row align-items-center gy-3 gy-md-0 gx-xl-5">
                <div class="col-xs-12 col-md-6">
                  <div class="img-wrapper text-center">
                      <img class="text-center" loading="lazy" src={getaccessMobile} alt="getaccessMobile" />
                  </div>
                </div>
                <div class="col-xs-12 col-md-6">
                  <div>
                    <p style={{fontWeight:"normal", fontSize:28 }} class="mb-3 text-center">
                      Be the First to Get Our App.
                    
                    </p>
                  </div>
                  

                <div class="eat-right-mb" style={{justifyContent:"center" }} >
                      <p style={{ fontWeight:'normal', fontSize:"16" }} class="eat-right-mb mb-4">
                        We are giving away exciting gifts for the first 1000 people to get the Livio App. Join the waitlist now to get notified immediately the app launches. We are excited to welcome you into our community of people who want to make smarter food choices.
                      </p>
                      <button onClick={() =>{ navigate("/waitlist")}} style={{marginLeft: "20%",  borderColor:'#30B15C', borderWidth: 3, color: '#30B15C', fontWeight:'bold' }} class="eat-right-mb btn fs-5 btn-md btn-outline-secondary rounded-pill px-20" >Get Early Access</button>
                    </div>
                    
                    <div class="eat-right-mb" style={{marginTop: 40,}}>
                      <p style={{marginLeft:"25%", fontWeight:600}} class="text-secondary text-dark">Available soon on</p>
                      <div >
                        <img style={{width:"45%", marginRight:'5%'}} src={applestore} alt="apple" />
                        {/* <img class="playstore" src={applestore} alt="apple" /> */}
                        <img  style={{width:"50%"}} src={playstore} alt="playstore" />
                      </div>
                    </div>
                    {/* <--End Of Show on Mobile, Hide on Laptop ---> */}
                    </div>

                
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <-- Show on Mobile, Hide on Laptop ---> */}

     

    </>
  );
}

export default GetAccessPage;





// import 'bootstrap/dist/css/bootstrap.css';
// import getaccess from "../assets/getaccess.png";


// function GetAccessPage() {
//   return (
//     <>
//         <section class="py-3 py-md-5">
//             <div class="container overflow-hidden">
//                     <div class="row gy-5">
//                     <div class="col-12">
//                         <div class="row align-items-center gy-3 gy-md-0 gx-xl-5">
//                         <div class="col-xs-12 col-md-6">
//                             <div class="img-wrapper position-relative bsb-hover-push">
//                             <a href="#!">
//                                 <span class="badge rounded-pill text-bg-warning position-absolute top-0 start-0 m-3">Photography</span>
//                                 <img class="img-fluid rounded w-100 h-100 object-fit-cover" loading="lazy" src={getaccess} alt="Photography" />
//                             </a>
//                             </div>
//                         </div>
//                         <div class="col-xs-12 col-md-6">
//                             <div>
//                             <p class="text-secondary mb-1">Sep 17, 2022</p>
//                             <h2 class="h1 mb-3"><a class="link-dark text-decoration-none" href="#!">Get into your best shape you're proud of</a></h2>
//                             <p class="mb-4">
//                                 With our Meal Planner, you gain insights into your eating patterns, and earn points based on your food intake and activity. Every week, get advice on how to improve and change your relationship with food for good.
//                             </p>
//                             <a class="btn btn-primary" href="#!" target="_self">Join Waitlist</a>
//                             </div>
//                         </div>
//                         </div>
//                     </div>

//                 </div>
//             </div>
//         </section>



//     </>
//   );
// }

// export default GetAccessPage;


