import 'bootstrap/dist/css/bootstrap.css';
import eatwell from "../../assets/eatwell.png";
// import adddark from "../../assets/adddark.png";
import applestore from "../../assets/applestore.svg";
import playstore from "../../assets/playstore.svg";
import { useNavigate } from "react-router-dom";
import "../../styles/index.css"

function EatRightPage() {
  let navigate = useNavigate();

  return (
    <>
      <div > 
      <section style={{marginTop:20, }} class="container py-0 py-md-5">
        <div class="container overflow-hidden">
          <div class="row gy-5">
            <div class="col-12">
              <div class="row align-items-center gy-3 gy-md-0 gx-xl-5">
                 
                 {/* <-- Show on Mobile, Hide on Laptop ---> */}
                <h1 style={{ fontWeight:'normal', marginLeft: "5%"}} class="eat-right-mb display-5"><a class="link-dark text-decoration-none" href="#!">
                        Eat Right, Worry Less
                        {/* <img class="position-absolute" src={adddark} style={{marginLeft: -23, marginBottom: 100, marginTop: -15}} alt="+"  /> */}
                        </a>
                </h1>
                {/* <-- End of Show on Mobile, Hide on Laptop ---> */}

                <div class="col-xs-12 col-md-6 gap-1">
                  <div class="img-wrapper position-relative bsb-hover-push">
                    <a href="#!">
                      <img class="img-fluid rounded w-100 h-100 " loading="lazy" src={eatwell} alt="Eat Well" />
                    </a>
                  </div>
                </div>
                <div class="col-xs-12 col-md-6">
                  

                  <div >
                    <h1 style={{marginTop:'10', fontWeight:'normal', }} class="eat-right display-5"><a class="link-dark text-decoration-none" href="#!">
                      Eat Right, Worry Less
                      {/* <img class="position-absolute" src={adddark} style={{marginLeft: -23, marginBottom: 100, marginTop: -15}} alt="+"  /> */}
                      </a>
                    </h1>
                    <p style={{marginTop:20, fontWeight:'normal'}} class="eat-right fs-3 mb-4">
                      Stop stressing over what to eat next. Our nutrition experts plan meals that fit your body goals, body type, and meal budget.
                    </p>
                    <button onClick={() =>{ navigate("/waitlist")}} style={{borderColor:'#30B15C', borderWidth: 3, color: '#30B15C', fontWeight:'bold' }} class="eat-right btn fs-5 btn-lg btn-outline-secondary rounded-pill px-20" >Get Early Access</button>


                    {/* <-- Show on Mobile, Hide on Laptop ---> */}
                    <div class="eat-right-mb" style={{justifyContent:"center" }} >
                      <p style={{ fontWeight:'normal',marginLeft:"5%" }} class="eat-right-mb fs-4 mb-4">
                        Stop stressing over what to eat next.
                        Our nutrition experts plan meals that fit 
                        your body goals, body type, and meal 
                        budget.
                      </p>
                      <button onClick={() =>{ navigate("/waitlist")}} style={{marginLeft: "20%",  borderColor:'#30B15C', borderWidth: 3, color: '#30B15C', fontWeight:'bold' }} class="eat-right-mb btn fs-5 btn-md btn-outline-secondary rounded-pill px-20" >Get Early Access</button>
                    </div>
                    
                    <div class="eat-right-mb" style={{marginTop: 40,}}>
                      <p style={{marginLeft:"25%", fontWeight:600}} class="text-secondary text-dark">Available soon on</p>
                      <div >
                        <img style={{width:"45%", marginRight:'5%'}} src={applestore} alt="apple" />
                        {/* <img class="playstore" src={applestore} alt="apple" /> */}
                        <img  style={{width:"50%"}} src={playstore} alt="playstore" />
                      </div>
                    </div>
                    {/* <--End Of Show on Mobile, Hide on Laptop ---> */}


                    <div class="eat-right" style={{marginTop: 50}}>
                      <p class="text-secondary text-dark">Available soon on</p>
                      <div >
                        <img style={{width:"30%", marginRight:'5%'}} src={applestore} alt="apple" />
                        {/* <img class="playstore" src={applestore} alt="apple" /> */}
                        <img  style={{width:"35%"}} src={playstore} alt="playstore" />
                      </div>
                    </div>

                  </div>

                  
                </div>

                
              </div>
            </div>
          </div>
        </div>
      </section>
      </div>
     

    </>
  );
}

export default EatRightPage;


