import 'bootstrap/dist/css/bootstrap.css';
//https://www.educba.com/bootstrap-vertical-menu/
//https://stackoverflow.com/questions/48248975/bootstrap-navbar-collapse-dont-work-react-create-react-app
// https://getbootstrap.com/docs/4.0/components/navbar/

import dashboardLogo from "../../assets/dashboardLogo.png";
import dashboard from "../../assets/dashboard.png";
import arrowRight from "../../assets/arrowRight.png";
import "../../styles/hover.css"

// import DashboardPageIndex from '.';
import DashboardRecipeTable from './dashboardRecipeTable';
import DashboardRecipeGraphIndex from './dashboardRecipeGraphIndex';


function DashboardNavBar() {
  return (
    <>
        <div style={{backgroundColor:"#FAFBFF"}} class="row">
            <div class="col-3" style={{width:"19%", backgroundColor:"#FFFFFF"}}>
                <nav class ="navbar">
                    <button style={{marginTop:"20%", border:"none", marginLeft:"5%"}} class="navbar-brand navbar-toggler" data-toggle="collapse" data-target="#colp" href="#">
                        <img src={dashboardLogo}  class="d-inline-block align-top" alt="credpalLogo" />
                    </button>
                    {/* <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#colp">
                     <span class="navbar-toggler-icon" style={{backgroundColor:"darkblue"}}></span>
                    </button> */}
                    <div style={{marginTop:"20%", }} class="collapse navbar-collapse" id="colp">
                        <ul class ="nav navbar-nav">
                            <li  class ="nav-item">
                                <a class="nav-link mybtn" href="#" style={{marginLeft: 2, padding:"6%",  fontSize:14, color:"#696F8C", display:"flex" , justifyContent:"space-between",   }}>
                                        <div class="mybtn">
                                            <img src={dashboard} class="d-inline-block" alt="credpalLogo" style={{marginRight: 8, marginTop:-3}}  /> 
                                            Dashboard
                                        </div>
                                        <img src={arrowRight} style={{marginTop:"2%"}} class="d-inline-block h-50"  alt=">" />  
                                </a>
                            </li>
                            <li  class ="nav-item">
                                <a class ="nav-link mybtn" href="#" style={{marginLeft: 2, padding:"6%", fontSize:14, color:"#696F8C",  display:"flex" , justifyContent:"space-between",   }}>
                                            <div class="mybtn">
                                                <img src={dashboard} class="d-inline-block" alt="credpalLogo" style={{marginRight: 8, marginTop:-3}}  /> 
                                                 Users
                                            </div>
                                            <img src={arrowRight} class="d-inline-block h-50"  alt=">" />  
                                </a>
                            </li>
                            <li  class ="nav-item">
                                <a class ="nav-link mybtn" href="#" style={{marginLeft: 2, padding:"6%", fontSize:14, color:"#696F8C", display:"flex" , justifyContent:"space-between",   }}>
                                                <div class="mybtn">
                                                    <img src={dashboard} class="d-inline-block" alt="credpalLogo" style={{marginRight: 8, marginTop:-3}}  /> 
                                                    All-stars
                                                </div>
                                                <img src={arrowRight} class="d-inline-block h-50"  alt=">" />  
                                </a>
                            </li>
                            <li  class ="nav-item">
                                <a class ="nav-link mybtn" href="#" style={{marginLeft: 2, padding:"6%", fontSize:14, color:"#696F8C", display:"flex" , justifyContent:"space-between",   }}>
                                                    <div class="mybtn">
                                                        <img src={dashboard} class="d-inline-block" alt="credpalLogo" style={{marginRight: 8, marginTop:-3}}  /> 
                                                        Content Creators
                                                    </div>
                                                    <img src={arrowRight} class="d-inline-block h-50"  alt=">" />  
                                </a>
                            </li>


                            <li style={{marginTop:"10%"}} class="nav-item dropdown">
                                <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Dropdown link
                                </a>
                                <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                <a class="dropdown-item" href="#">Action</a>
                                <a class="dropdown-item" href="#">Another action</a>
                                <a class="dropdown-item" href="#">Something else here</a>
                                </div>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>

            <div style={{backgroundColor:"#FAFBFF"}} class="col-9">
                    {/* <DashboardPageIndex /> */}
                    <DashboardRecipeGraphIndex />
                    <DashboardRecipeTable />
            </div>
        </div>
        
      

    </>
  );
}

export default DashboardNavBar;





// import 'bootstrap/dist/css/bootstrap.css';
// //https://www.educba.com/bootstrap-vertical-menu/
// //https://stackoverflow.com/questions/48248975/bootstrap-navbar-collapse-dont-work-react-create-react-app
// // https://getbootstrap.com/docs/4.0/components/navbar/

// import credpalLogo from "../../assets/credpalLogo.png";


// function DashboardNavBar() {
//   return (
//     <>
//         <div class="row">
//             <div class="col-3">
//                 <nav class ="navbar bg-light">
//                     <button class="navbar-brand navbar-toggler" data-toggle="collapse" data-target="#colp" href="#">
//                         <img src={credpalLogo}  class="d-inline-block align-top" alt="credpalLogo" />
//                     </button>
//                     {/* <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#colp">
//                      <span class="navbar-toggler-icon" style={{backgroundColor:"darkblue"}}></span>
//                     </button> */}
//                     <div class="collapse navbar-collapse" id="colp">
//                         <ul class ="nav navbar-nav">
//                             <li class ="nav-item">
//                               <a class ="nav-link" href="#"> Dashboard </a>
//                             </li>
//                             <li class ="nav-item">
//                              <a class ="nav-link" href="#"> Users </a>
//                             </li>
//                             <li class ="nav-item">
//                              <a class ="nav-link" href="#"> All-stars </a>
//                             </li>
//                             <li class ="nav-item">
//                                 <a class ="nav-link" href="#"> Content Creators </a>
//                             </li>


//                             <li class="nav-item dropdown">
//                                 <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
//                                 Dropdown link
//                                 </a>
//                                 <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
//                                 <a class="dropdown-item" href="#">Action</a>
//                                 <a class="dropdown-item" href="#">Another action</a>
//                                 <a class="dropdown-item" href="#">Something else here</a>
//                                 </div>
//                             </li>
//                         </ul>
//                     </div>
//                 </nav>
//             </div>

//             <div class="col-9">
//                 <div class="container">
//                  The Web Content in detail.
//                 </div>
//                 <div class ="container">
//                     <p> The vertical menu can place the left or right side of the web pages. <br /> But the default vertical menu placed on the left side. </p>
//                 </div>
//             </div>
//         </div>
        
      

//     </>
//   );
// }

// export default DashboardNavBar;


