import 'bootstrap/dist/css/bootstrap.css';
import React, { useState} from "react";
import dashboardLogo from "../../assets/dashboardLogo.png";



function DashboardNavBar2() {
    return(
        <>
            {/* <!-- Header --> */}
<header id="header-demo">
  <nav class="navbar navbar-expand-sm bg-body-tertiary">
    <div class="container">
      <a class="navbar-brand d-sm-none" href="#!">
        <img src={dashboardLogo} class="img-fluid" alt="BootstrapBrain Logo" width="135" height="44" />
      </a>
      <button class="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#bsbNavbar" aria-controls="bsbNavbar" aria-label="Toggle Navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="offcanvas offcanvas-end" tabIndex="-1" id="bsbNavbar" aria-labelledby="bsbNavbarLabel">
        <div class="offcanvas-header">
          <h5 class="offcanvas-title" id="bsbNavbarLabel">Menu</h5>
          <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body">
          <ul class="navbar-nav">
            <li class="navbar-toggler-icon nav-item me-3">
              <a class="nav-link" href="#!" data-bs-toggle="offcanvas" data-bs-target="#bsbSidebar1" aria-controls="bsbSidebar1">
                <i class="bi-filter-left fs-3 lh-1"></i>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link active" aria-current="page" href="#!">Home</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" aria-current="page" href="#!">Blog</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" aria-current="page" href="#!">Contact</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </nav>
</header>

{/* <!-- Main --> */}
<div id="main-demo">
  <section class="my-3 my-md-4">
    <div class="container">
      <div class="row gy-3">
        <div class="col-6 col-md-4 col-xl-2">
          <h2 class="d-flex flex-column align-items-center justify-content-center bg-body-secondary rounded display-6 p-5">
            <i class="bi-airplane mb-3"></i>
            <span class="fs-7 text-body-secondary">Airplane</span>
          </h2>
        </div>
        <div class="col-6 col-md-4 col-xl-2">
          <h2 class="d-flex flex-column align-items-center justify-content-center bg-body-secondary rounded display-6 p-5">
            <i class="bi-alarm mb-3"></i>
            <span class="fs-7 text-body-secondary">Alarm</span>
          </h2>
        </div>
        <div class="col-6 col-md-4 col-xl-2">
          <h2 class="d-flex flex-column align-items-center justify-content-center bg-body-secondary rounded display-6 p-5">
            <i class="bi-archive mb-3"></i>
            <span class="fs-7 text-body-secondary">Archive</span>
          </h2>
        </div>
        <div class="col-6 col-md-4 col-xl-2">
          <h2 class="d-flex flex-column align-items-center justify-content-center bg-body-secondary rounded display-6 p-5">
            <i class="bi-aspect-ratio mb-3"></i>
            <span class="fs-7 text-body-secondary">Ratio</span>
          </h2>
        </div>
        <div class="col-6 col-md-4 col-xl-2">
          <h2 class="d-flex flex-column align-items-center justify-content-center bg-body-secondary rounded display-6 p-5">
            <i class="bi-award mb-3"></i>
            <span class="fs-7 text-body-secondary">Award</span>
          </h2>
        </div>
        <div class="col-6 col-md-4 col-xl-2">
          <h2 class="d-flex flex-column align-items-center justify-content-center bg-body-secondary rounded display-6 p-5">
            <i class="bi-bag mb-3"></i>
            <span class="fs-7 text-body-secondary">Bag</span>
          </h2>
        </div>
        <div class="col-6 col-md-4 col-xl-2">
          <h2 class="d-flex flex-column align-items-center justify-content-center bg-body-secondary rounded display-6 p-5">
            <i class="bi-balloon mb-3"></i>
            <span class="fs-7 text-body-secondary">Baloon</span>
          </h2>
        </div>
        <div class="col-6 col-md-4 col-xl-2">
          <h2 class="d-flex flex-column align-items-center justify-content-center bg-body-secondary rounded display-6 p-5">
            <i class="bi-balloon-heart mb-3"></i>
            <span class="fs-7 text-body-secondary">Baloon</span>
          </h2>
        </div>
        <div class="col-6 col-md-4 col-xl-2">
          <h2 class="d-flex flex-column align-items-center justify-content-center bg-body-secondary rounded display-6 p-5">
            <i class="bi-ban mb-3"></i>
            <span class="fs-7 text-body-secondary">Ban</span>
          </h2>
        </div>
        <div class="col-6 col-md-4 col-xl-2">
          <h2 class="d-flex flex-column align-items-center justify-content-center bg-body-secondary rounded display-6 p-5">
            <i class="bi-bank mb-3"></i>
            <span class="fs-7 text-body-secondary">Bank</span>
          </h2>
        </div>
        <div class="col-6 col-md-4 col-xl-2">
          <h2 class="d-flex flex-column align-items-center justify-content-center bg-body-secondary rounded display-6 p-5">
            <i class="bi-bar-chart mb-3"></i>
            <span class="fs-7 text-body-secondary">Chart</span>
          </h2>
        </div>
        <div class="col-6 col-md-4 col-xl-2">
          <h2 class="d-flex flex-column align-items-center justify-content-center bg-body-secondary rounded display-6 p-5">
            <i class="bi-basket mb-3"></i>
            <span class="fs-7 text-body-secondary">Basket</span>
          </h2>
        </div>
        <div class="col-6 col-md-4 col-xl-2">
          <h2 class="d-flex flex-column align-items-center justify-content-center bg-body-secondary rounded display-6 p-5">
            <i class="bi-battery mb-3"></i>
            <span class="fs-7 text-body-secondary">Battery</span>
          </h2>
        </div>
        <div class="col-6 col-md-4 col-xl-2">
          <h2 class="d-flex flex-column align-items-center justify-content-center bg-body-secondary rounded display-6 p-5">
            <i class="bi-bell mb-3"></i>
            <span class="fs-7 text-body-secondary">Bell</span>
          </h2>
        </div>
        <div class="col-6 col-md-4 col-xl-2">
          <h2 class="d-flex flex-column align-items-center justify-content-center bg-body-secondary rounded display-6 p-5">
            <i class="bi-book mb-3"></i>
            <span class="fs-7 text-body-secondary">Book</span>
          </h2>
        </div>
        <div class="col-6 col-md-4 col-xl-2">
          <h2 class="d-flex flex-column align-items-center justify-content-center bg-body-secondary rounded display-6 p-5">
            <i class="bi-briefcase mb-3"></i>
            <span class="fs-7 text-body-secondary">Briefcase</span>
          </h2>
        </div>
        <div class="col-6 col-md-4 col-xl-2">
          <h2 class="d-flex flex-column align-items-center justify-content-center bg-body-secondary rounded display-6 p-5">
            <i class="bi-brightness-high mb-3"></i>
            <span class="fs-7 text-body-secondary">Brightness</span>
          </h2>
        </div>
        <div class="col-6 col-md-4 col-xl-2">
          <h2 class="d-flex flex-column align-items-center justify-content-center bg-body-secondary rounded display-6 p-5">
            <i class="bi-brush mb-3"></i>
            <span class="fs-7 text-body-secondary">Brush</span>
          </h2>
        </div>
        <div class="col-6 col-md-4 col-xl-2">
          <h2 class="d-flex flex-column align-items-center justify-content-center bg-body-secondary rounded display-6 p-5">
            <i class="bi-bug mb-3"></i>
            <span class="fs-7 text-body-secondary">Bug</span>
          </h2>
        </div>
        <div class="col-6 col-md-4 col-xl-2">
          <h2 class="d-flex flex-column align-items-center justify-content-center bg-body-secondary rounded display-6 p-5">
            <i class="bi-calendar mb-3"></i>
            <span class="fs-7 text-body-secondary">Calendar</span>
          </h2>
        </div>
        <div class="col-6 col-md-4 col-xl-2">
          <h2 class="d-flex flex-column align-items-center justify-content-center bg-body-secondary rounded display-6 p-5">
            <i class="bi-camera mb-3"></i>
            <span class="fs-7 text-body-secondary">Camera</span>
          </h2>
        </div>
        <div class="col-6 col-md-4 col-xl-2">
          <h2 class="d-flex flex-column align-items-center justify-content-center bg-body-secondary rounded display-6 p-5">
            <i class="bi-camera-video mb-3"></i>
            <span class="fs-7 text-body-secondary">Video</span>
          </h2>
        </div>
        <div class="col-6 col-md-4 col-xl-2">
          <h2 class="d-flex flex-column align-items-center justify-content-center bg-body-secondary rounded display-6 p-5">
            <i class="bi-cart-plus mb-3"></i>
            <span class="fs-7 text-body-secondary">Cart</span>
          </h2>
        </div>
        <div class="col-6 col-md-4 col-xl-2">
          <h2 class="d-flex flex-column align-items-center justify-content-center bg-body-secondary rounded display-6 p-5">
            <i class="bi-cast mb-3"></i>
            <span class="fs-7 text-body-secondary">Cast</span>
          </h2>
        </div>
        <div class="col-6 col-md-4 col-xl-2">
          <h2 class="d-flex flex-column align-items-center justify-content-center bg-body-secondary rounded display-6 p-5">
            <i class="bi-chat mb-3"></i>
            <span class="fs-7 text-body-secondary">Chat</span>
          </h2>
        </div>
        <div class="col-6 col-md-4 col-xl-2">
          <h2 class="d-flex flex-column align-items-center justify-content-center bg-body-secondary rounded display-6 p-5">
            <i class="bi-check-lg mb-3"></i>
            <span class="fs-7 text-body-secondary">Check</span>
          </h2>
        </div>
        <div class="col-6 col-md-4 col-xl-2">
          <h2 class="d-flex flex-column align-items-center justify-content-center bg-body-secondary rounded display-6 p-5">
            <i class="bi-chevron-double-up mb-3"></i>
            <span class="fs-7 text-body-secondary">Chevron</span>
          </h2>
        </div>
        <div class="col-6 col-md-4 col-xl-2">
          <h2 class="d-flex flex-column align-items-center justify-content-center bg-body-secondary rounded display-6 p-5">
            <i class="bi-slash-circle mb-3"></i>
            <span class="fs-7 text-body-secondary">Slash</span>
          </h2>
        </div>
        <div class="col-6 col-md-4 col-xl-2">
          <h2 class="d-flex flex-column align-items-center justify-content-center bg-body-secondary rounded display-6 p-5">
            <i class="bi-clipboard mb-3"></i>
            <span class="fs-7 text-body-secondary">Clipboard</span>
          </h2>
        </div>
        <div class="col-6 col-md-4 col-xl-2">
          <h2 class="d-flex flex-column align-items-center justify-content-center bg-body-secondary rounded display-6 p-5">
            <i class="bi-clock mb-3"></i>
            <span class="fs-7 text-body-secondary">Clock</span>
          </h2>
        </div>
        <div class="col-6 col-md-4 col-xl-2">
          <h2 class="d-flex flex-column align-items-center justify-content-center bg-body-secondary rounded display-6 p-5">
            <i class="bi-cloud mb-3"></i>
            <span class="fs-7 text-body-secondary">Cloud</span>
          </h2>
        </div>
        <div class="col-6 col-md-4 col-xl-2">
          <h2 class="d-flex flex-column align-items-center justify-content-center bg-body-secondary rounded display-6 p-5">
            <i class="bi-cloud-fog2 mb-3"></i>
            <span class="fs-7 text-body-secondary">Cloud</span>
          </h2>
        </div>
        <div class="col-6 col-md-4 col-xl-2">
          <h2 class="d-flex flex-column align-items-center justify-content-center bg-body-secondary rounded display-6 p-5">
            <i class="bi-code mb-3"></i>
            <span class="fs-7 text-body-secondary">Code</span>
          </h2>
        </div>
        <div class="col-6 col-md-4 col-xl-2">
          <h2 class="d-flex flex-column align-items-center justify-content-center bg-body-secondary rounded display-6 p-5">
            <i class="bi-collection mb-3"></i>
            <span class="fs-7 text-body-secondary">Collection</span>
          </h2>
        </div>
        <div class="col-6 col-md-4 col-xl-2">
          <h2 class="d-flex flex-column align-items-center justify-content-center bg-body-secondary rounded display-6 p-5">
            <i class="bi-copy mb-3"></i>
            <span class="fs-7 text-body-secondary">Copy</span>
          </h2>
        </div>
        <div class="col-6 col-md-4 col-xl-2">
          <h2 class="d-flex flex-column align-items-center justify-content-center bg-body-secondary rounded display-6 p-5">
            <i class="bi-cursor mb-3"></i>
            <span class="fs-7 text-body-secondary">Cursor</span>
          </h2>
        </div>
      </div>
    </div>
  </section>
</div>

{/* <!-- Aside --> */}
<aside class="bsb-sidebar-1 offcanvas offcanvas-start" tabIndex="-1" id="bsbSidebar1" aria-labelledby="bsbSidebarLabel1">
  <div class="offcanvas-header">
    <a class="sidebar-brand" href="#!">
      <img src={dashboardLogo} id="bsbSidebarLabel1" class="img-fluid" alt="BootstrapBrain Logo" width="135" height="44" />
    </a>
    <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </div>
  <div class="offcanvas-body pt-0">
    <hr class="sidebar-divider mb-3" />
    <ul class="navbar-nav">
      <li class="nav-item">
        <a class="nav-link p-3 active bg-light rounded" data-bs-toggle="collapse" href="#dashboardExamples" role="button" aria-expanded="true" aria-controls="dashboardExamples">
          <div class="nav-link-icon text-primary">
            <i class="bi bi-house-gear"></i>
          </div>
          <span class="nav-link-text fw-bold">Dashboards</span>
        </a>
        <div class="collapse show" id="dashboardExamples">
          <ul class="nav flex-column ms-4">
            <li class="nav-item">
              <a class="nav-link active" aria-current="page" href="#!">
                <div class="nav-link-icon text-primary-emphasis">
                  <i class="bi bi-arrow-right-short"></i>
                </div>
                <span class="nav-link-text">Default</span>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link link-secondary" aria-current="page" href="#!">
                <div class="nav-link-icon text-primary-emphasis">
                  <i class="bi bi-arrow-right-short"></i>
                </div>
                <span class="nav-link-text">Sales</span>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link link-secondary" aria-current="page" href="#!">
                <div class="nav-link-icon text-primary-emphasis">
                  <i class="bi bi-arrow-right-short"></i>
                </div>
                <span class="nav-link-text">Marketing</span>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link link-secondary" aria-current="page" href="#!">
                <div class="nav-link-icon text-primary-emphasis">
                  <i class="bi bi-arrow-right-short"></i>
                </div>
                <span class="nav-link-text">Directory</span>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link link-secondary" aria-current="page" href="#!">
                <div class="nav-link-icon text-primary-emphasis">
                  <i class="bi bi-arrow-right-short"></i>
                </div>
                <span class="nav-link-text">Analytic</span>
              </a>
            </li>
          </ul>
        </div>
      </li>
      <li class="nav-item mt-3">
        <h6 class="py-1 text-secondary text-uppercase fs-7">Pages</h6>
      </li>
      <li class="nav-item">
        <a class="nav-link p-3" data-bs-toggle="collapse" href="#pageExamples" role="button" aria-expanded="false" aria-controls="pageExamples">
          <div class="nav-link-icon text-danger">
            <i class="bi bi-folder"></i>
          </div>
          <span class="nav-link-text fw-bold">Pages</span>
        </a>
        <div class="collapse" id="pageExamples">
          <ul class="nav flex-column ms-4">
            <li class="nav-item">
              <a class="nav-link link-secondary" aria-current="page" href="#!">
                <div class="nav-link-icon text-danger-emphasis">
                  <i class="bi bi-arrow-right-short"></i>
                </div>
                <span class="nav-link-text">Account</span>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link link-secondary" aria-current="page" href="#!">
                <div class="nav-link-icon text-danger-emphasis">
                  <i class="bi bi-arrow-right-short"></i>
                </div>
                <span class="nav-link-text">Profile</span>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link link-secondary" aria-current="page" href="#!">
                <div class="nav-link-icon text-danger-emphasis">
                  <i class="bi bi-arrow-right-short"></i>
                </div>
                <span class="nav-link-text">Users</span>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link link-secondary" aria-current="page" href="#!">
                <div class="nav-link-icon text-danger-emphasis">
                  <i class="bi bi-arrow-right-short"></i>
                </div>
                <span class="nav-link-text">Projects</span>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link link-secondary" aria-current="page" href="#!">
                <div class="nav-link-icon text-danger-emphasis">
                  <i class="bi bi-arrow-right-short"></i>
                </div>
                <span class="nav-link-text">Invoice</span>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link link-secondary" aria-current="page" href="#!">
                <div class="nav-link-icon text-danger-emphasis">
                  <i class="bi bi-arrow-right-short"></i>
                </div>
                <span class="nav-link-text">Pricing</span>
              </a>
            </li>
          </ul>
        </div>
      </li>
      <li class="nav-item">
        <a class="nav-link p-3" data-bs-toggle="collapse" href="#authExamples" role="button" aria-expanded="false" aria-controls="authExamples">
          <div class="nav-link-icon text-success">
            <i class="bi bi-gear"></i>
          </div>
          <span class="nav-link-text fw-bold">Authentication</span>
        </a>
        <div class="collapse" id="authExamples">
          <ul class="nav flex-column ms-4">
            <li class="nav-item">
              <a class="nav-link link-secondary" aria-current="page" href="#!">
                <div class="nav-link-icon text-success-emphasis">
                  <i class="bi bi-arrow-right-short"></i>
                </div>
                <span class="nav-link-text">Sign In</span>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link link-secondary" aria-current="page" href="#!">
                <div class="nav-link-icon text-success-emphasis">
                  <i class="bi bi-arrow-right-short"></i>
                </div>
                <span class="nav-link-text">Sign Up</span>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link link-secondary" aria-current="page" href="#!">
                <div class="nav-link-icon text-success-emphasis">
                  <i class="bi bi-arrow-right-short"></i>
                </div>
                <span class="nav-link-text">Reset Password</span>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link link-secondary" aria-current="page" href="#!">
                <div class="nav-link-icon text-success-emphasis">
                  <i class="bi bi-arrow-right-short"></i>
                </div>
                <span class="nav-link-text">Lock</span>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link link-secondary" aria-current="page" href="#!">
                <div class="nav-link-icon text-success-emphasis">
                  <i class="bi bi-arrow-right-short"></i>
                </div>
                <span class="nav-link-text">Error</span>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link link-secondary" aria-current="page" href="#!">
                <div class="nav-link-icon text-success-emphasis">
                  <i class="bi bi-arrow-right-short"></i>
                </div>
                <span class="nav-link-text">2FA</span>
              </a>
            </li>
          </ul>
        </div>
      </li>
      <li class="nav-item">
        <a class="nav-link p-3" data-bs-toggle="collapse" href="#ecommerceExamples" role="button" aria-expanded="false" aria-controls="ecommerceExamples">
          <div class="nav-link-icon text-info">
            <i class="bi bi-cart"></i>
          </div>
          <span class="nav-link-text fw-bold">Ecommerce</span>
        </a>
        <div class="collapse" id="ecommerceExamples">
          <ul class="nav flex-column ms-4">
            <li class="nav-item">
              <a class="nav-link link-secondary" aria-current="page" href="#!">
                <div class="nav-link-icon text-info-emphasis">
                  <i class="bi bi-arrow-right-short"></i>
                </div>
                <span class="nav-link-text">Overview</span>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link link-secondary" aria-current="page" href="#!">
                <div class="nav-link-icon text-info-emphasis">
                  <i class="bi bi-arrow-right-short"></i>
                </div>
                <span class="nav-link-text">Products</span>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link link-secondary" aria-current="page" href="#!">
                <div class="nav-link-icon text-info-emphasis">
                  <i class="bi bi-arrow-right-short"></i>
                </div>
                <span class="nav-link-text">Orders</span>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link link-secondary" aria-current="page" href="#!">
                <div class="nav-link-icon text-info-emphasis">
                  <i class="bi bi-arrow-right-short"></i>
                </div>
                <span class="nav-link-text">Referral</span>
              </a>
            </li>
          </ul>
        </div>
      </li>
      <li class="nav-item">
        <a class="nav-link p-3" data-bs-toggle="collapse" href="#componentExamples" role="button" aria-expanded="false" aria-controls="componentExamples">
          <div class="nav-link-icon text-warning">
            <i class="bi bi-database-check"></i>
          </div>
          <span class="nav-link-text fw-bold">Components</span>
        </a>
        <div class="collapse" id="componentExamples">
          <ul class="nav flex-column ms-4">
            <li class="nav-item">
              <a class="nav-link link-secondary" aria-current="page" href="#!">
                <div class="nav-link-icon text-warning-emphasis">
                  <i class="bi bi-arrow-right-short"></i>
                </div>
                <span class="nav-link-text">Buttons</span>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link link-secondary" aria-current="page" href="#!">
                <div class="nav-link-icon text-warning-emphasis">
                  <i class="bi bi-arrow-right-short"></i>
                </div>
                <span class="nav-link-text">Charts</span>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link link-secondary" aria-current="page" href="#!">
                <div class="nav-link-icon text-warning-emphasis">
                  <i class="bi bi-arrow-right-short"></i>
                </div>
                <span class="nav-link-text">Forms</span>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link link-secondary" aria-current="page" href="#!">
                <div class="nav-link-icon text-warning-emphasis">
                  <i class="bi bi-arrow-right-short"></i>
                </div>
                <span class="nav-link-text">Icons</span>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link link-secondary" aria-current="page" href="#!">
                <div class="nav-link-icon text-warning-emphasis">
                  <i class="bi bi-arrow-right-short"></i>
                </div>
                <span class="nav-link-text">Widgets</span>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link link-secondary" aria-current="page" href="#!">
                <div class="nav-link-icon text-warning-emphasis">
                  <i class="bi bi-arrow-right-short"></i>
                </div>
                <span class="nav-link-text">Notifications</span>
              </a>
            </li>
          </ul>
        </div>
      </li>
      <li class="nav-item mt-3">
        <h6 class="py-1 text-secondary text-uppercase fs-7">Docs</h6>
      </li>
      <li class="nav-item">
        <a class="nav-link p-3" data-bs-toggle="collapse" href="#basicExamples" role="button" aria-expanded="false" aria-controls="basicExamples">
          <div class="nav-link-icon text-dark">
            <i class="bi bi-pen"></i>
          </div>
          <span class="nav-link-text fw-bold">Basic</span>
        </a>
        <div class="collapse" id="basicExamples">
          <ul class="nav flex-column ms-4">
            <li class="nav-item">
              <a class="nav-link link-secondary" aria-current="page" href="#!">
                <div class="nav-link-icon text-dark-emphasis">
                  <i class="bi bi-arrow-right-short"></i>
                </div>
                <span class="nav-link-text">Getting Started</span>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link link-secondary" aria-current="page" href="#!">
                <div class="nav-link-icon text-dark-emphasis">
                  <i class="bi bi-arrow-right-short"></i>
                </div>
                <span class="nav-link-text">Foundation</span>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link link-secondary" aria-current="page" href="#!">
                <div class="nav-link-icon text-dark-emphasis">
                  <i class="bi bi-arrow-right-short"></i>
                </div>
                <span class="nav-link-text">FAQs</span>
              </a>
            </li>
          </ul>
        </div>
      </li>
      <li class="nav-item">
        <a class="nav-link p-3" data-bs-toggle="collapse" href="#frameworkExamples" role="button" aria-expanded="false" aria-controls="frameworkExamples">
          <div class="nav-link-icon text-dark">
            <i class="bi bi-shield-plus"></i>
          </div>
          <span class="nav-link-text fw-bold">Framework</span>
        </a>
        <div class="collapse" id="frameworkExamples">
          <ul class="nav flex-column ms-4">
            <li class="nav-item">
              <a class="nav-link link-secondary" aria-current="page" href="#!">
                <div class="nav-link-icon text-dark-emphasis">
                  <i class="bi bi-arrow-right-short"></i>
                </div>
                <span class="nav-link-text">Developers</span>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link link-secondary" aria-current="page" href="#!">
                <div class="nav-link-icon text-dark-emphasis">
                  <i class="bi bi-arrow-right-short"></i>
                </div>
                <span class="nav-link-text">API</span>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link link-secondary" aria-current="page" href="#!">
                <div class="nav-link-icon text-dark-emphasis">
                  <i class="bi bi-arrow-right-short"></i>
                </div>
                <span class="nav-link-text">Changelog</span>
              </a>
            </li>
          </ul>
        </div>
      </li>
    </ul>
    <hr class="sidebar-divider my-4" />
    <div class="bg-light rounded-3 position-relative px-4 pt-5 pb-4 mt-7">
      <div class="bsb-w-80 bsb-h-80 d-flex align-items-center justify-content-center text-bg-primary border border-5 border-white rounded-circle position-absolute top-0 start-50 translate-middle">
        <i class="bi bi-rocket-takeoff lh-1 fs-3"></i>
      </div>
      <div class="text-center">
        <h3 class="h5">Pro Snippets</h3>
        <p class="fs-7">Our meticulously crafted code snippets are handy for everyday tasks, allowing you to breeze through your projects.</p>
        <div class="d-grid m-0">
          <a class="btn btn-primary rounded-pill" href="https://bootstrapbrain.com/license/" role="button">Upgrade</a>
        </div>
      </div>
    </div>
  </div>
</aside>

{/* <!-- Footer --> */}
<footer class="footer bg-body-tertiary">
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="py-3">
          Built by <a href="https://bootstrapbrain.com/" class="link-secondary text-decoration-none">BootstrapBrain</a> with <span class="text-primary">&#9829;</span>
        </div>
      </div>
    </div>
  </div>
</footer>
        </>

    );
}

export default DashboardNavBar2;


