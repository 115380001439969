import React, { useState} from "react";
import 'bootstrap/dist/css/bootstrap.css';
import adminLogin from "../../assets/adminLogin.png";
import waitlistbg from "../../assets/waitlistbg.png";
import NavBar from "../home/NavBar";
import { useNavigate } from "react-router-dom";
import success from "../../assets/success.png";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import google from "../../assets/google.svg";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

import "../../styles/index.css"

function AdminPageIndex() {
    let navigate = useNavigate();

    const [open, setOpen] = React.useState(false);
	const handleClose = () => setOpen(false);
	

    // For hide or show password
    const [passwordVisible, setPasswordVisible] = useState(false);
    const handlePasswordVisibility = () => {
      setPasswordVisible(!passwordVisible);
    };
    //End of for hide or show password


  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  


  const handleSubmit = async (e) => {
    e.preventDefault();

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("email", email);
    urlencoded.append("password", password);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    };

    fetch("https://staging.api.circlenow.app/livio/api/v1/waitlist", requestOptions)
      .then(response => response.json())
      .then(result => {
        setOpen(true);
      }
      )
      .catch(error => console.log('error', error));

  }



  return (
    <>
      <NavBar />

        <section style={{marginTop:20}} class="p-3 p-md-4 p-xl-5">

            {/* Beginning of div container */}
            <div  class="container">
                <div class="row ">

                    <div style={{paddingRight:15}} class="col-12 col-md-5">
                        <div class="row">
                            <div  class="col-12">
                                <div class="mt-5">
                                    <h2 style={{fontWeight:600}} class="h3 fw-bold">Welcome Back</h2>
                                    <h3 style={{marginTop:10}} class="fs-6 fw-normal text-secondary m-0">
                                       Welcome back! Please enter your details below;
                                    </h3>
                                </div>
                            </div>
                        </div>

                        <form style={{marginTop:50,}} onSubmit={handleSubmit}>
                            <div class="row gy-3 gy-md-4 overflow-hidden">
                                <div  class="col-12 mt-3">
                                    <label style={{color:"#474D66", fontWeight:'normal' }} for="email" class="form-label fw-bold">Email address <span class="text-danger">*</span></label>
                                    <input value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    style={{height:50, borderRadius:10, }} type="email" class="form-control" name="email" id="email" placeholder="Enter your email address" required />
                                </div>

                            
                                <div class="col-12">
                                    <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
                                        <label style={{color:"#474D66", fontWeight:'normal' }} for="password" class="form-label fw-bold">Password <span class="text-danger">*</span></label>
                                        <label style={{color:"#474D66", fontWeight:'normal', textDecoration:"underline" }} for="password" class="form-label">Forgot password </label>
                                    </div>

                                    <div class="input-group">
                                        <input type={passwordVisible ? 'text' : 'password'}
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                                style={{height:50, borderTopLeftRadius:10, }}
                                                class="form-control" name="password" id="password"  placeholder="Password"required />
                                        
                                        <span class="input-group-text">
                                            <FontAwesomeIcon
                                                icon={passwordVisible ? faEyeSlash : faEye}
                                                onClick={handlePasswordVisibility}
                                            />
                                        </span>
                                    </div>
                                
                                </div>

                                <div class="col-12">
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" name="iAgree" id="iAgree" required />
                                        <label style={{color:"#474D66", fontSize:16, fontWeight:600}} class="form-check-label text-secondary" for="iAgree">
                                            Remember my preference
                                        </label>
                                    </div>
                                </div>


                                <div class="col-12">
                                    <div class="d-grid">
                                    <button style={{backgroundColor:'#51D27D', height:50, borderRadius:10, color:'white', fontSize:16, fontWeight:600 }} class="btn bsb-btn-xl fw-bold" type="submit">Login</button>
                                    </div>
                                </div>

                            </div>
                        </form>

                        <div style={{marginTop:"5%"}} class="col-12">
                            <div class="d-grid">
                                <button style={{ backgroundColor:'#ffffff', height:50, borderRadius:10, color:'white' }} class="btn bsb-btn-xl fw-bold btn-outline-dark align-items-center" >
                                        <span style={{color:"#474D66", fontSize:16, fontWeight:500}} class="ms-2 fs-6 flex-grow-1">
                                            <img style={{marginRight:10, marginTop:-2}} loading="lazy" src={google} alt="google"  />
                                            Continue with Google
                                        </span>
                                </button>
                            </div>
                        </div>

                        <p style={{color:'#696F8C', marginTop:20, fontSize:14, fontWeight:500}} class="text-center">
                            Don’t have an account? 
                            <button style={{fontSize:14, fontWeight:"bold", color:"#51D27D", border:'none', background:'none' }} >Sign up today!</button>
                        </p>
                    </div>


                    {/*Beginning of Hide Image on mobile */}
                    <div style={{backgroundImage: `url(${waitlistbg})`,}} class="img-fluid col-12 col-md-1 text-center waitlistIndex">
                    </div>
                   
                    <div style={{backgroundImage: `url(${waitlistbg})`,}} class="col-12 col-md-6 text-center waitlistIndex">
                        <img style={{borderRadius:50 }} src={adminLogin} alt="adminLogin" loading="lazy"  class="img-fluid rounded w-100 h-100 object-fit-cover"  />
                    </div>
                    {/*End of Hide Image on mobile */}

                </div>
            </div>
            {/*End of div container */}





            {/* Modal is outside the div */}
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box style={{backgroundColor:'white',  top:'50%', width:400, position:'absolute', transform: 'translate(-50%, -50%)', left: '50%', borderRadius:'5%' }} >


                    <div  class="modal-dialog modal-dialog-centered modal-md rounded " role="document">
                        <div style={{borderRadius:20}} class="modal-content  ">
                            <div class="modal-body text-center p-lg-2 ">
                                <img style={{marginTop:50}} loading="lazy" src={success} alt="Success" />

                                <h4 style={{fontWeight:'initial' }} class="text-dark mt-4 fw-bold fs-4">You are now part of the</h4>
                                <p style={{color:'#30B15C', marginTop:-5 }} class=" fs-4 fw-bold">Livio family!</p>

                                <p style={{color:'#696F8C'}} class="mt-3 fs-6 text-secondary">
                                    Get early access to everything
                                    <br/>happening in the livio community!
                                </p>

                                <hr style={{width:"100%", height:2, marginTop:60, marginLeft:0, marginRight:0 }} />
                                <button  onClick={() =>{ navigate("/")}} type="button" style={{border:'none', alignSelf:"center", backgroundColor:"white", borderColor:'white', textDecoration:"underline", fontWeight:"bold", marginTop:40, marginBottom:40 }} class=" fs-5 text-dark link-secondary"  >Go Back Home</button>

                            </div>
                        </div>
                    </div>

                </Box>

            </Modal>


        </section>

    </>
  );
}

export default AdminPageIndex;


